import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProyectosView from '../views/ProyectosView.vue'
import IngenieriaView from '../views/IngenieriaView.vue'
import BodegaView from '../views/BodegaView.vue'
import TrasladobodegaView from '../views/TrasladobodegaView.vue'
import TrasladobodeganView from '../views/TrasladobodeganView.vue'
import RegistrarView from '../views/RegistrarView.vue'
import RecibocampoView from '../views/RecibocampoView.vue'
import ImprevistosView from '../views/ImprevistosView.vue'
import EmergenciaView from '../views/EmergenciaView.vue'
import TarifasView from '../views/TarifasView.vue'
import axios from 'axios'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/proyectos',
    name: 'proyectos',
    component: ProyectosView
  },
  {
    path: '/ingenieria',
    name: 'ingenieria',
    component: IngenieriaView
  },         
  {
    path: '/bodega',
    name: 'bodega',
    component: BodegaView
  },
  {
    path: '/trasladobodega',
    name: 'trasladobodega',
    component: TrasladobodegaView
  },
  {
    path: '/trasladobodegan',
    name: 'trasladobodegan',
    component: TrasladobodeganView
  },  
  {
    path: '/registrar',
    name: 'registrar',
    component: RegistrarView
  },
  {
    path: '/recibocampo',
    name: 'recibocampo',
    component: RecibocampoView
  },
  {
    path: '/imprevistos',
    name: 'imprevistos',
    component: ImprevistosView
  },
  {
    path: '/emergencia',
    name: 'emergencia',
    component: EmergenciaView
  },
  {
    path: '/tarifas',
    name: 'tarifas',
    component: TarifasView
  },                           
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
    //store.dispatch('logout')
    router.push('/')
  }
  return Promise.reject(error)
})

export default router
