<template>
  <div class="row">
  </div>
    <div class="row">
    </div>
    <div class="row bg-gradient-light p-1" style="margin:0% 0% 0% 0%">
    </div>
    <div class="row  mb-1 mt-1 mr-1">
      <div class="col-5"></div>
      <div class="col-7">
          <div v-show="this.pantalla" class="row">
            <div class="col-2">Lider</div>
            <div class="col-10">
            <select class="form-control" v-model="lider">
              <option v-for="item in this.items" :key="item" :value="item.nombre">{{ item.nombre }}</option>
            </select>
          </div>
          </div>  
        </div>
      </div>
    <div class="row" style="padding:1% 1% 1% 1%">
    <div v-show="this.pantalla" class="col-12">
      <div style="width: 100%;height: 500px;overflow-y:auto;overflow-x:hidden;">
      <table class="table table-striped"  style="font-size: 120%">
          <thead style="color:#fff;background-color: gray  !important;">
            <tr class="p-0 m-0"><td>cliente</td><td>pendiente</td></tr>
          </thead>
          <tbody>
            <tr v-for="itemsi in this.itemsi" :key="itemsi" @click="solicitar(itemsi.proyecto)">
              <td :style="{backgroundColor:itemsi.colorplanta}">{{itemsi.proyecto}}</td><td>{{itemsi.pendientes}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-show="!this.pantalla" class="col-12">
      <fullscreen v-model="fullscreen">
      <div style="width: 100%;height: 500px;overflow-y:auto;overflow-x:hidden;">
        <div class="row mt-0 ml-1 text-left">
          <div class="col-6">
              <h3>{{this.proyecto}}({{ this.pendientes }})</h3><hr>
              <h3>{{this.insumo}}</h3>
              <h2>Cantidad:{{this.cantidad}}</h2>
              <h2>Ubicación:{{this.ubicacion}}</h2><hr>
              <input type="text"  v-model="this.salida"  class="form-control" style="font-size: 120%">
          </div>
          <div class="col-6" style="position: relative;">
            <div class="row">
              <div class="col-3">
                <button class="button" @click="teclado('1')">1</button>
              </div>  
              <div class="col-3">
                <button class="button" @click="teclado('2')">2</button>
              </div>
              <div class="col-3">
                <button class="button"  @click="teclado('3')">3</button>
              </div>
              <div class="col-3">
                <button class="button"  @click="teclado('13')"><i class="fas fa-angle-double-up" style="font-size: 1.5rem; color: white;"></i></button>
              </div>              
            </div>
            <div class="row">
              <div class="col-3">
                <button class="button"  @click="teclado('4')">4</button>
              </div>  
              <div class="col-3">
                <button class="button"  @click="teclado('5')">5</button>
              </div>
              <div class="col-3">
                <button class="button"  @click="teclado('6')">6</button>
              </div>
              <div class="col-3">
                <button class="button"  @click="teclado('12')"><i class="fas fa-angle-double-down" style="font-size: 1.5rem; color: white;"></i></button>
              </div>                
            </div>
            <div class="row">
              <div class="col-3">
                <button class="button"  @click="teclado('7')">7</button>
              </div>  
              <div class="col-3">
                <button class="button"  @click="teclado('8')">8</button>
              </div>
              <div class="col-3">
                <button class="button"  @click="teclado('9')">9</button>
              </div>
              <div class="col-3">
              </div>                
            </div>
            <div class="row">
              <div class="col-3">
                <button class="button"  @click="teclado('10')"><i class="fas fa-angle-left" style="font-size: 1.5rem; color: white;"></i></button>
              </div>  
              <div class="col-3">
                <button class="button"  @click="teclado('0')">0</button>
              </div>
              <div class="col-3">
                <button class="button" @click="teclado('11')"><i class="fas fa-angle-double-right" style="font-size: 1.5rem; color: white;"></i></button>
              </div>
              <div class="col-3">
              </div>                
            </div>                        
          </div>
          </div>
      </div>
    </fullscreen>
    </div>
    </div>
    </template>

  <script>
  require('@/css/sb-admin-2.min.css')
  require('@/vendor/fontawesome-free/css/all.min.css')
  import axios from 'axios'
  import CryptoJS from 'crypto-js'
  export default 
  {
      name: "HomeView",
      data: function () {
      return {
        cantidad:0,items:[],itemsi:[],itemsj:{},msj:'',lider:'',pantalla:1,salida:'',requerida:0,identificacion:'',proyecto:'',actual:[],insumo:'',codigo:0,contador:0,pendientes:0,fullscreen: false,
      };
    },
    beforeMount() {
          let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
          let json={"api_token":token,};
          axios.post("https://apigestion.coenergia.app/lideres",json,)
          .then(data=>{
                this.items=data.data
                axios.post("https://apigestion.coenergia.app/pendientesenviar",json,)
                .then(data=>{
                  this.itemsi=data.data
                              })
                    .catch(error => {
                          console.log(error);
                          this.msj='Error de registro';
                          this.enable=1;
                          }
                          )
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
          
    },
    methods: {
      toggle () {
        this.fullscreen = !this.fullscreen
      },
      toggleApi () {
        this.$fullscreen.toggle()
      },
      solicitar(proyecto)
        {
          if (this.lider!="")
          {
            let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
            let json={"api_token":token,"proyecto":proyecto};
            axios.post("https://apigestion.coenergia.app/picking",json,)
            .then(data=>{
              this.contador=0
              this.itemsj=data.data
              this.pendientes=this.itemsj.length
              this.identificacion=this.itemsj[this.contador].id
              this.proyecto=this.itemsj[this.contador].proyecto
              this.cantidad=this.itemsj[this.contador].cantidad
              this.insumo=this.itemsj[this.contador].insumo
              this.ubicacion=this.itemsj[this.contador].codigo
              this.pantalla=0
              })
                .catch(error => {
                      console.log(error);
                      this.msj='Error de registro';
                      this.enable=1;
                      }
                      )
          }
          else
          {
            alert('Debe seleccionar un lider')
          }
        },
        enviar()
        {
              let token=(CryptoJS.AES.decrypt(localStorage.d1,"G3st10n").toString(CryptoJS.enc.Utf8));
              let usuario=(CryptoJS.AES.decrypt(localStorage.d2,"G3st10n").toString(CryptoJS.enc.Utf8));
              let json={"api_token":token,"proyecto":this.proyecto,"id":this.identificacion,"cantidad":this.salida,"usuario":usuario,"lider":this.lider};
              axios.post("https://apigestion.coenergia.app/procesarenvio",json,)
              .then(data=>{
                this.salida=''
                this.itemsj=data.data
                this.pendientes=this.itemsj.length
                this.contador=0
                this.identificacion=this.itemsj[this.contador].id
                this.proyecto=this.itemsj[this.contador].proyecto
                this.cantidad=this.itemsj[this.contador].cantidad
                this.insumo=this.itemsj[this.contador].insumo
                this.ubicacion=this.itemsj[this.contador].codigo
                this.pantalla=0
                        })
              .catch(error => {
                    console.log(error);
                    this.msj='Error de registro';
                    this.enable=1;
                    }
                    )
        },
        refrescar(ruta)
        {
          this.$router.push(ruta);
        },
        teclado(digito)
        {
          console.log(digito)
          if (digito=="10" || digito=="11"|| digito=="12"|| digito=="13")
            {
                if (digito=="10")
                {
                this.salida=this.salida.substring(0,this.salida.length-1)
                }
                if (digito=="11")
                {
                  if(this.salida!='')
                  {
                  if((this.cantidad-parseInt(this.salida, 10))<0)
                    {
                      alert('no se puede scar una cantidad mayor')
                    }
                    else
                    {
                      this.enviar()
                    }
                  }
                }
                if (digito=="12")
                {
                  this.contador=this.contador+1
                  if (this.contador>this.itemsj.length)
                  {
                    this.contador=this.itemsj.length
                  }
                  this.identificacion=this.itemsj[this.contador].id
                  this.proyecto=this.itemsj[this.contador].proyecto
                  this.cantidad=this.itemsj[this.contador].cantidad
                  this.insumo=this.itemsj[this.contador].insumo
                  this.ubicacion=this.itemsj[this.contador].codigo
                }
                if (digito=="13")
                {
                  this.contador=this.contador-1
                  if (this.contador<0)
                  {
                    this.contador=0
                  }
                  this.identificacion=this.itemsj[this.contador].id
                  this.proyecto=this.itemsj[this.contador].proyecto
                  this.cantidad=this.itemsj[this.contador].cantidad
                  this.insumo=this.itemsj[this.contador].insumo
                  this.ubicacion=this.itemsj[this.contador].codigo
                }                

            }
          else{
            this.salida=this.salida+digito
          }  
        }
      }}
  </script>

<style>
.button {
  background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 10px 38px;
  margin: 2px 0px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 12px;
  font-size: 25px;
}
.button {
  transition-duration: 0.4s;
}

.button:hover {
  background-color: red; /* Green */
  color: white;
}
</style>